<template>
  <section class="container690  winner_list">
    <div class="background_winner d-flex justify-content-center align-items-center mb-3">
      <h3 style="color: #fff;">Winner</h3>
    </div>
    <div class="current_table" v-if="winners.is_current">
      <h4 class="px-3">This year's winners</h4>
      <div class="p-3">
        <el-table :data="currentList" style="width: 100%" header-align="left" tooltip-effect="light">
          <el-table-column :label="v" v-for="(v, k, i) in currentHead" :key="i" show-overflow-tooltip
            :width="k=='business_name'?140:''" :min-width="k=='category'?90:''">
            <template slot-scope="scope">
              <span>{{scope.row[k]?scope.row[k]:'-'}}</span>
            </template>
          </el-table-column>
        </el-table>
        <div style="width: 100%; text-align: right;" class="mt-2">
          <el-pagination layout="prev, pager, next" :total="currentTotal" hide-on-single-page
            @current-change="handleChangeCurrentPage" :page-size="5"></el-pagination>
        </div>
      </div>
    </div>
    <div class="past_table mt-3" v-if="winners.is_past">
      <div class="d-flex align-items-center justify-content-between px-3 past_select">
        <h4>Past Winners</h4>
        <el-select v-model="year" placeholder="Select an Year" style="width: 140px;" @change="changeYear">
          <el-option v-for="(v,i) in winners.past_year" :key="i" :label="v" :value="v">
          </el-option>
        </el-select>
      </div>
      <div class="p-3">
        <el-table :data="pastList" style="width: 100%" header-align="left" tooltip-effect="light">
          <el-table-column :label="v" v-for="(v, k, i) in pastHead" :key="i" show-overflow-tooltip
            :width="k=='business_name'?140:''" :min-width="k=='category'?90:''">
            <template slot-scope="scope">
              <span>{{scope.row[k]?scope.row[k]:'-'}}</span>
            </template>
          </el-table-column>
        </el-table>
        <div style="width: 100%; text-align: right;" class="mt-2">
          <el-pagination layout="prev, pager, next" :total="pastTotal" hide-on-single-page
            @current-change="handleChangePastPage" :page-size="5" :current-page="pastPage"></el-pagination>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        winners: {},
        currentList: [],
        currentHead: {},
        pastList: [],
        pastHead: [],
        year: '',
        currentTotal: 0,
        currentPage: 1,
        pastTotal: 0,
        pastPage: 1
      };
    },

    created() {
      this.getProgramDetail()
    },

    methods: {
      getProgramDetail() {
        this.$http.getProgramDetail({
          program_id: this.$route.query.id,
          type: "winners",
        }).then((res) => {
          this.winners = res.data;
          if (this.winners.is_current) this.getCurrentWinner()
          if (this.winners.is_past) {
            if (this.$route.query.year) {
              this.year = this.$route.query.year
            } else {
              this.year = this.winners.past_year[0]
            }
            this.getPastWinner()
          }
        });
      },

      changeYear() {
        this.pastPage = 1
        this.getPastWinner()
      },

      handleChangeCurrentPage(page) {
        this.currentPage = page
        this.getCurrentWinner()
      },

      handleChangePastPage(page) {
        this.pastPage = page
        this.getPastWinner()
      },

      getCurrentWinner() {
        this.$http.getProgramDetail({
          program_id: this.$route.query.id,
          type: "winners_personnel",
          is_now: 1,
          per_page: 5,
          page: this.currentPage
        }).then((res) => {
          this.currentList = res.data.result.data;
          this.currentHead = res.data.row;
          this.currentTotal = res.data.result.total;
        });
      },

      getPastWinner() {
        this.$http.getProgramDetail({
          program_id: this.$route.query.id,
          type: "winners_personnel",
          is_now: 2,
          year: this.year,
          per_page: 5,
          page: this.pastPage
        }).then((res) => {
          this.pastList = res.data.result.data;
          this.pastHead = res.data.row;
          this.pastTotal = res.data.result.total;
        });
      },
    }
  };
</script>
<style lang="scss" scoped>
  .background_winner {
    height: 230px;
    border-radius: 8px;
    overflow: hidden;
    background: url("../../../../assets/images/donation/third_bg.png");
  }

  .current_table,
  .past_table {
    background: #fff;
    width: 100%;
  }

  .winner_list {
    /deep/ .el-table thead tr th {
      background-color: #F4F7F9 !important;
    }

    .past_select {
      border-bottom: 1px solid #f1f1f1;

      h4 {
        border-bottom: 0;
      }
    }

    h4 {
      color: #333;
      font-size: 20px;
      line-height: 60px;
      border-bottom: 1px solid #f1f1f1;
    }
  }
</style>